<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              ref="dxGridRef"
              title="Brands"
              canSelect="none"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadBrands()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  :data-field="item.field"
                  :caption="item.caption"
                  v-for="(item, index) in fields"
                  :key="index"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      hide-footer
      v-model="modalShow"
      centered
      @hide="closeModal()"
      size="lg"
    >
      <validation-observer ref="refCategory" #default="{ invalid }">
        <b-row>
          <b-col cols="12" md="6" v-if="imagePreview">
            <b-row>
              <b-col cols="12" v-if="imagePreview">
                <b-form-group label="Preview">
                  <b-img center thumbnail fluid :src="imagePreview" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" :md="imagePreview ? '6' : '12'">
            <b-row>
              <b-col>
                <b-form-group label="Image">
                  <b-form-file
                    ref="imageRef"
                    accept="image/*"
                    v-model="imageUpload"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  vid="v-category_name"
                  rules="required"
                >
                  <b-form-group label="Name" label-for="v-name">
                    <b-form-input
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      v-model="formData.name"
                      name="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Brand ID" label-for="v-name">
                  <b-form-input v-model="formData.brand_id" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Website" label-for="v-name">
                  <b-form-input v-model="formData.website" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button
                size="sm"
                @click="closeModal()"
                variant="danger"
                class="bg-darken-4 mr-1"
              >
                Cancel
              </b-button>
              <b-button
                :disabled="invalid"
                size="sm"
                @click="onSubmit()"
                variant="primary"
                class="bg-darken-4"
              >
                Save
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </section>
</template>

<script>
import brandsApi from '@api/brands';
import { getImage } from '@/global-functions';
import { required } from '@validations';
import { queryParameters } from '@/schema';

export default {
  name: 'BrandsPage',
  components: {},
  data: () => ({
    required,
    modalShow: false,
    imageUpload: null,
    imagePreview: null,
    imageName: null,
    imageExt: null,
    dataSource: [],
    selectedData: [],
    totalCount: 0,
    page: 0,
    size: 0,
    fields: [
      { caption: 'Brand ID', field: 'brand_id' },
      { caption: 'Name', field: 'name' },
    ],

    formData: {
      thumbnail: null,
      thumbnailbase64: null,
    },
  }),
  watch: {
    page(v) {
      this.loadBrands();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadBrands();
      }
    },
    imageUpload(e) {
      if (e) {
        const reader = new window.FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          this.imageExt = e.name.split('.')[1];
          this.imageName = e.name;
          this.imagePreview = reader.result;

          this.formData.file = e.name;
          this.formData.thumbnail = e.name;
          this.formData.thumbnailbase64 = reader.result;
        };
      }
    },
  },
  mounted() {
    this.loadBrands();
  },
  methods: {
    loadBrands() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      brandsApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
          }

          this.totalCount = meta.page.total;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const closeModalloadBrands = () => {
        this.loadBrands();
        this.closeModal();
      };

      this.$refs.refCategory.validate().then((success) => {
        if (success) {
          if (this.formData.id) {
            brandsApi
              .update(this.formData)
              .then(({ data }) => {
                closeModalloadBrands();
              })
              .catch(() => {
                //
              })
              .finally(() => {
                //
              });

            return;
          }

          brandsApi
            .add(this.formData)
            .then(({ data }) => {
              closeModalloadBrands();
            })
            .catch(() => {
              //
            })
            .finally(() => {
              //
            });
        }
      });
    },
    onOpenModal(event) {
      this.modalShow = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      const getImageData = {
        model: 'brands',
        id: data.id,
        thumbnail: data.thumbnail,
      };

      data.url = getImage(getImageData);

      if (data.thumbnailbase64 !== '') {
        this.imagePreview = data.thumbnailbase64;
      } else {
        this.imagePreview = data.url;
      }

      this.$nextTick(() => {
        this.formData = Object.assign({}, data);
      });
    },
    onDelete(event) {
      brandsApi
        .delete(event)
        .then(() => {
          this.loadBrands();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.modalShow = false;
      const defaultForm = {
        thumbnail: null,
        thumbnailbase64: null,
      };

      this.$nextTick(() => {
        this.imagePreview = '';
        this.formData = Object.assign({}, defaultForm);
      });
    },
  },
};
</script>

<style></style>
